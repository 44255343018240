<template>
    <div>
        <h1>Shipping</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './shipping_opt.vue'
    import Fields from './utils/Fields.vue'
    import Results from "./utils/Results";

    export default {
        name: "shipping",
        components: {
            Options,
            Fields,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    startDate: {val: new Date().toLocaleDateString(), qsField: '[dates][scheduled_ship_from]'},
                    endDate: {val: new Date().toLocaleDateString(), qsField: '[dates][scheduled_ship_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                    includeCdiStock: {val: null, qsField: '[include_cdi_stock]'},
                    remainingProduction: {val: null, qsField: '[remaining_value]'},
                    showAllLocationTotal: {val: null, qsField: '[show_all_location_totals]'},
                    canReceive: {val: 0, qsField: '[numbers][t.can_receive]'}
                },
                groupByFields: {},
                dataFields: {
                    tracker_id: {label: 'Order', checked: true},
                    job_tag: {label: 'Job Tag', checked: true},
                    distributor_name: {label: 'Customer', checked: true},
                    email: {label: 'Email', checked: false},
                    distributor_po: {label: 'Purchase Order #', checked: true},
                    shipping_co: {label: 'Shipper', checked: true},
                    qty: {label: 'Item Count', checked: false},
                    zip: {label: 'Shipto Zip', checked: false},
                    ship_weight: {label: 'Weight (lb)', checked: false},
                    scheduled_ship: {label: 'Sch Ship', checked: true},
                    actual_ship: {label: 'Ship Date', checked: true},
                    can_receive: {label: 'Can Receive', checked: true},
                    company_name: {label: 'Company', checked: false},
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },

        created() {
            this.$appStore.setTitle(['Shipping']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>